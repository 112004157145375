import { createContext, useEffect, useState } from "react";
import axios from "axios";
import Loader from "components/Loader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
	const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [companyIsQuerying, setCompanyIsQuerying] = useState(false);
	const [accessToken, setAccessToken] = useState(JSON.parse(localStorage.getItem("ATOB_b2b_access_token")) || false);
	const [isAuthed, setIsAuthed] = useState(!!accessToken);
	const [company, setCompany] = useState(JSON.parse(localStorage.getItem("ATOB_company")) || false);
	const [companyUpdateFlag, setCompanyUpdateFlag] = useState(false);
	const [hasCompany, setHasCompany] = useState(!!company?.name);
	const [companyIsActive, setCompanyIsActive] = useState(!!company?.status);
	const [currency, setCurrency] = useState(JSON.parse(localStorage.getItem("ATOB_currency")) || false);
	const [currencies, setCurrencies] = useState(JSON.parse(localStorage.getItem("ATOB_currencies")) || []);
	const [credits, setCredits] = useState(0);

	const currentVersion = "0.4.1";

	axios.interceptors.request.use(
		config => {
			config.headers = {
				...config.headers,
				Accept: "application/json",
				// Only needed to bypass Cloudflare gateway on test server
				// "CF-Access-Client-Id": `${process.env.REACT_APP_CF_ID}`,
				// "CF-Access-Client-Secret": `${process.env.REACT_APP_CF_SECRET}`,
			};

			return config;
		},
		error => {
			return Promise.reject(error);
		}
	);

	axios.interceptors.response.use(
		response => {
			return response;
		},
		error => {
			if (error.response && error.response.status === 401 && window.location.pathname !== "/") {
				logout();
			}

			return Promise.reject(error);
		}
	);

	const updateCompany = (navigate_to = null) => {
		return new Promise(res => {
			axios
				.get(`${process.env.REACT_APP_BASE_API_URL}/my-company`, {
					headers: {
						Authorization: "Bearer " + accessToken,
					},
				})
				.then(function (response) {
					if (response?.data.success) {
						setCompany(response.data.company);
						setHasCompany(!!response.data.company.name);
						setCompanyIsActive(!!response.data.company.status);
						setCompanyUpdateFlag(!companyUpdateFlag);
					} else {
						setCompany(false);
						setHasCompany(false);
						setCompanyIsActive(false);
						setCompanyUpdateFlag(!companyUpdateFlag);
					}
					if (navigate_to) {
						navigate(navigate_to);
					}
					res();
				})
				.catch(error => {
					if (error.response?.status === 422) {
						setCompany(false);
						setHasCompany(false);
						setCompanyIsActive(false);
						setCompanyUpdateFlag(!companyUpdateFlag);
					} else {
						console.log(error);
					}
					res();
				});
		});
	};

	const removeCompany = () => {
		return new Promise(res => {
			setCompany(false);
			setHasCompany(false);
			setCompanyIsActive(false);
			setCompanyUpdateFlag(!companyUpdateFlag);
			res();
		});
	};

	useEffect(() => {
		setCompanyIsQuerying(true);
		isAuthed ? updateCompany() : removeCompany();
	}, [isAuthed]);

	useEffect(() => {
		setCompanyIsQuerying(false);
		company
			? localStorage.setItem("ATOB_company", JSON.stringify(company))
			: localStorage.removeItem("ATOB_company");
	}, [companyUpdateFlag]);

	const getCurrency = () => {
		axios
			.get(`${process.env.REACT_APP_BASE_API_URL}/get-my-currency`, {
				headers: {
					Authorization: "Bearer " + JSON.parse(localStorage.getItem("ATOB_b2b_access_token")),
				},
			})
			.then(response => {
				if (response.data.success) {
					console.log(response.data);
					setCurrency(prev_currency => {
						let new_currency = response.data.currency;
						return new_currency;
					});
				}
			})
			.catch(error => {
				console.log(error);
			});
	};

	const getCurrencies = () => {
		axios
			.get(`${process.env.REACT_APP_BASE_API_URL}/sign-up/settings/get-currencies`, {
				headers: {
					Authorization: "Bearer " + JSON.parse(localStorage.getItem("ATOB_b2b_access_token")),
				},
			})
			.then(response => {
				if (response.data.success) {
					setCurrencies(response.data.currencies);
				}
			})
			.catch(error => {
				console.log(error);
			});
	};

	const getCredits = () => {
		axios
			.get(`${process.env.REACT_APP_BASE_API_URL}/get-credits`, {
				headers: {
					Authorization: "Bearer " + accessToken,
				},
			})
			.then(res => {
				setCredits(res.data.credit_amount);
			})
			.catch(error => {});
	};

	const deleteLocalStorage = () => {
		for (let i = 0; i < localStorage.length; i++) {
			const key = localStorage.key(i);
			if (key.startsWith("ATOB_")) {
				localStorage.removeItem(key);
			}
		}
	};

	useEffect(() => {
		if (isAuthed) {
			getCurrencies();
		}

		if (hasCompany && !currency) {
			getCurrency();
		}
		const storedVersion = localStorage.getItem("app_version");
		if (storedVersion !== currentVersion) {
			deleteLocalStorage();
			localStorage.setItem("app_version", currentVersion);
			logout();
		}
	}, []);

	const logout = () => {
		return new Promise(res => {
			localStorage.removeItem("ATOB_b2b_access_token");
			setAccessToken(false);
			setIsAuthed(false);
			res();
		});
	};

	useEffect(() => {
		localStorage.setItem("ATOB_currency", JSON.stringify(currency));
	}, [currency]);

	useEffect(() => {
		localStorage.setItem("ATOB_currencies", JSON.stringify(currencies));
	}, [currencies]);

	useEffect(() => {
		const handleResize = () => {
			setViewportWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const appContext = {
		// Navigation
		// Authentification
		accessToken,
		isAuthed,
		login(token) {
			return new Promise(res => {
				localStorage.setItem("ATOB_b2b_access_token", JSON.stringify(token));
				setAccessToken(token);
				setIsAuthed(!!token);
				res();
			});
		},
		logout,
		// Company
		company,
		hasCompany,
		companyIsActive,
		updateCompany,
		removeCompany,
		companyUpdateFlag,
		setCompanyUpdateFlag,
		// Language
		t,
		// Currencies
		currency,
		currencies,
		setCurrency,
		setCurrencies,
		getCurrency,
		getCurrencies,
		//Credits
		credits,
		getCredits,
		// viewportWidth
		viewportWidth,
	};

	return <AppContext.Provider value={appContext}>{!companyIsQuerying ? children : <Loader />}</AppContext.Provider>;
};

export default AppContextProvider;
